import React, { useEffect, useState } from "react";
import { Typography, useMediaQuery, Grid, Hidden } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import theme from "../gatsby-theme-material-ui-top-layout/theme";

// import GalleryModal from './galleryModal'

const styles = {
  root: {
    marginTop: theme.spacing(8),
  },

  tile: {
    "& > div": {
      "& > div": {
        height: "100%",
      },
    },
  },
  galleryRow: {
    // padding: theme.spacing(1),
  },
  imageWrapper: {
    display: "flex",
  },
  captionWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
};

const Gallery = (props) => {
  const { project, width } = props;
  const { gallery } = project;

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // no css workaround - fix this later
  const [render, setRender] = useState(false);
  useEffect(() => setRender(true), []);

  const spacing = mdUp ? 10 : 4;

  return (
    render && (
      <>
        <Grid container direction="column" spacing={spacing} sx={styles.root}>
          {gallery.map((entry, index) => {
            const left = Boolean(index % 2);
            return (
              <Grid
                key={Math.random().toString()}
                item
                container
                justifyContent="center"
                flexDirection="row"
                // alignItems={mdUp ? "initial" : "flex-end"}
                spacing={2}
                direction={mdUp ? "row" : "column"}
                sx={styles.galleryRow}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  order={left ? 2 : 1}
                  sx={{
                    ...styles.imageWrapper,
                    justifyContent: !mdUp
                      ? "center"
                      : left
                      ? "flex-start"
                      : "flex-end",
                  }}
                >
                  <GatsbyImage
                    image={getImage(entry.small)}
                    title={entry.title}
                    alt={entry.description}
                  />
                </Grid>
                <Hidden mdDown>
                  <Grid
                    item
                    md={4}
                    order={left ? 1 : 2}
                    sx={styles.captionWrapper}
                  >
                    <Typography variant="h6" align={left ? "right" : "left"}>
                      {entry.title
                        .replace(", designed by Bureau V Architecture", "")
                        .replace(", designed by Bureau V", "")}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
            );
          })}
        </Grid>
      </>
    )
  );
};

export default Gallery;
