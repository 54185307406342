import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PageLayout from "../components/pageLayout";
// import ContentLayout from '../components/contentLayout'
import Markdown from "../components/markdown";
import Gallery from "../components/gallery";

import { genHelmet } from "../actions/baseActions";

// import { processLayoutCollection } from '../actions/contentfulActions'

import { Typography, Grid } from "@mui/material";

import { Link } from "gatsby-theme-material-ui";

import theme from "../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {
    background: "#fff",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7.5),

    minHeight: "100vh",
    textAlign: "left",
    // width: '100%',
    overflowX: "hidden",
    // maxWidth: '960px',
    margin: "auto",
  },
  title: {
    margin: `0 auto ${theme.spacing(4)} auto`,
  },
  byline: {
    margin: `${theme.spacing(2)} 0 0 0`,
  },
  gridRoot: {
    backgroundColor: "#fff",
  },
  coverImage: {
    width: "100%",
    height: "auto",
  },
  coverImageWrapper: {
    margin: `-${theme.spacing(12)} -${theme.spacing(3)} 0 -${theme.spacing(3)}`,
  },
};

const ProjectTemplate = (props) => {
  console.log(props);

  const project = props.data.contentfulProject;
  const { coverImage, markdown, gallery } = project;
  console.log(project);

  return (
    <React.Fragment>
      {genHelmet(project, {
        type: "project",
        siteData: props.pageContext.site,
        path: props.path,
      })}

      <PageLayout location={props.location}>
        {/* <ContentLayout layoutCollection={project.layoutCollection} /> */}
        <div style={styles.coverImageWrapper}>
          <GatsbyImage
            image={getImage(coverImage.gatsbyImageData)}
            title={coverImage.title}
            alt={coverImage.description}
            style={styles.coverImage}
          />
        </div>

        {/* --- MAIN BODY --- */}
        <div style={styles.root}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="center"
            sx={styles.gridRoot}
          >
            {/* TITLE */}
            <Grid item xs={12} lg={9}>
              <div style={styles.title}>
                <Typography variant="h2"> {project.title} </Typography>
                <Typography variant="h6"> {project.locationString} </Typography>
                {project.author && (
                  <Typography variant="h5" style={styles.byline}>
                    by {project.author}
                  </Typography>
                )}
              </div>
            </Grid>

            {/* MARKDOWN */}
            <Grid item xs={12} lg={9}>
              <Markdown
                markdown={markdown}
                gallery={project.gallery}
                suppressLinkUnderlines
              />
            </Grid>

            {/* GALLERY */}
            {gallery && (
              <Grid item xs={12} lg={9}>
                <Gallery project={project} />
              </Grid>
            )}
          </Grid>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};

export default ProjectTemplate;

export const ProjectQuery = graphql`
  query ProjectById($id: String!) {
    contentfulProject(contentful_id: { eq: $id }) {
      slug
      title
      locationString
      featured
      headline
      createdAt
      author
      updatedAt
      markdown {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        description
        small: gatsbyImageData(width: 600)
        large: gatsbyImageData(width: 1500)
      }
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
